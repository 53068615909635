<template>
  <div class="ProjectPage">
    <div style="height: 1px" />
    <div class="maintop_img">
      <img style="z-index: 9" src="@/assets/img/activeTwelve/4.png" alt="" />
      <div class="left_grid">
        <img src="@/assets/img/activeTwelve/1.png" alt="" />
      </div>
      <div class="right_grid">
        <img src="@/assets/img/activeTwelve/27.png" alt="" />
      </div>
      <div class="active_time">
        <img src="@/assets/img/activeTwelve/23.png" alt="" />
      </div>
      <div class="active_til">
        <img src="@/assets/img/activeTwelve/28.png" alt="" />
      </div>
    </div>
    <div class="coupon">
      <div class="lantern_left">
        <img src="@/assets/img/activeTwelve/7.png" alt="" />
      </div>
      <div class="lantern_right">
        <img src="@/assets/img/activeTwelve/6.png" alt="" />
      </div>
      <div style="height: 1px"></div>
      <div class="coupon_list">
        <div @click="getSudentJXUStatus()" style="width: 309px">
          <img src="@/assets/img/activeTwelve/35.png" alt="" />
        </div>
        <div @click="getSudentJXUStatus()">
          <img src="@/assets/img/activeTwelve/36.png" alt="" />
        </div>
        <div @click="getSudentJXUStatus()">
          <img src="@/assets/img/activeTwelve/38.png" alt="" />
        </div>
      </div>
      <div class="coupon_mid">
        <img src="@/assets/img/activeTwelve/2.png" alt="" />
      </div>
      <div @click="getSudentJXUStatus()" class="coupon_bot">
        <img src="@/assets/img/activeTwelve/3.png" alt="" />
      </div>
    </div>
    <div class="reputation">
      <div class="reputation_til">
        <img src="@/assets/img/activeTwelve/21.png" alt="" />
      </div>
      <div class="reputation_til_item">
        <div class="reputation_item">
          <div>《统招专升本——全科基础班》</div>
          <img src="@/assets/img/activeTwelve/39.png" alt="" />
        </div>
        <div @click="getSudentJXUStatus()" class="reputation_get">
          <img src="@/assets/img/activeTwelve/26.png" alt="" />
        </div>
      </div>
    </div>
    <div class="twelve">
      <div class="twelve_til">
        <img src="@/assets/img/activeTwelve/25.png" alt="" />
      </div>
      <div style="height: 1px;"></div>
      <div class="twelve_list">
        <div class="twelve_lists">
          <div @click="xmchange(1)" :class="a == 1 ? 'twelve_list_item  search1' : 'twelve_list_item'">行政管理</div>
          <div @click="xmchange(2)" :class="a == 2 ? 'twelve_list_item  search1' : 'twelve_list_item'">工商管理</div>
          <div @click="xmchange(3)" :class="a == 3 ? 'twelve_list_item  search1' : 'twelve_list_item'">会计学</div>
          <div @click="xmchange(4)" :class="a == 4 ? 'twelve_list_item  search1' : 'twelve_list_item'">汉语言文学</div>
          <div @click="xmchange(5)" :class="a == 5 ? 'twelve_list_item  search1' : 'twelve_list_item'">学前教育</div>
          <div @click="xmchange(6)" :class="a == 6 ? 'twelve_list_item  search1' : 'twelve_list_item'">小学教育</div>
        </div>
      </div>
      <div class="item_list">
        <div @click="getSudentJXUStatus()" v-for="item, index in tablelist" :key="index" class="list_item">
          <div class="item_til"> {{ item }}</div>
        </div>

      </div>
    </div>
    <div class="twelves">

    </div>
    <div class="fot">
      <div class="fot_connect">
        <p>1.所有优惠活动限活动页面相关内容，活动优惠不与活动外其它促销折扣同享；</p>
        <p>2.新人豪礼、口碑好课、真题大礼包三款福利任选其一领；</p>
        <p>3.双十二活动时间：2023年12月08日00:00:00至2023年12月20日24:00:00；</p>
        <p>4.本次活动的最终解释权归聚学u平台所有，如有疑问请联系课程顾问。</p>
      </div>

    </div>
    <div v-if="isShowRegin" class="pageAll" @click="isShowRegin = false">
      <div class="upDio">
        <img src="@/assets/img/activeTwelve/46.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { Know } from '@/api/know'
const know = new Know()
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { set } from 'date-fns'
export default {
  data() {
    return {
      isShowRegin: false,
      a1: ['西方行政学说史', '公务员制度', '社会学概论', '普通逻辑', '行政法学', '中国行政史'],
      a2: ['国际贸易理论与实务', '企业管理咨询', '企业经营战略', '组织行为学', '管理系统中计算机应用', '质量管理'],
      a3: ['审计学', '资产评估', '金融理论与实务', '概率论与数理统计', '财务报表分析', '会计制度设计'],
      a4: ['外国文学史', '中国现代文学史', '现代汉语语法研究', '中国古代文论选读', '中国现当代作家作品研究', '外国作家作品专题研究'],
      a5: ['学前比较教育', '儿童发展理论', '学前教育研究方法', '学前教育心理学', '学前教育原理', '学前卫生学'],
      a6: ['发展与教育心理学', '小学教育研究方法', '发展与教育心理学', '小学课程与教学设计', '小学心理辅导', '小学艺术教育'],
      tablelist: ['西方行政学说史', '公务员制度', '社会学概论', '普通逻辑', '行政法学', '中国行政史'],
      a: 1
    }

  },
  created() {
    setInterval(() => {
      if (this.a == 6) {
        this.a = 1
        this.xmchange(this.a)
      } else {
        this.a++
        this.xmchange(this.a)
      }
    }, 2000);
  },
  methods: {
    terShow() {
      this.isShowRegin = true;
    },
    xmchange(val) {
      this.a = val
      if (val == 1) {
        this.tablelist = this.a1
      } else if (val == 2) {
        this.tablelist = this.a2
      } else if (val == 3) {
        this.tablelist = this.a3
      } else if (val == 4) {
        this.tablelist = this.a4
      } else if (val == 5) {
        this.tablelist = this.a5
      } else if (val == 6) {
        this.tablelist = this.a6
      }
    },
    getSudentJXUStatus() {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)

        return
      }

      know.getSudentJXUStatus({ type: 0 }).then((res) => {
        if (res.data = false) {
          this.isShowRegin = true;

        } else {
          know.getSudentJXUStatus({ type: 1 }).then((ress) => {
            this.isShowRegin = true;

          })
        }

      })
    },
  },
};
</script>

<style lang="scss" scoped>
.ProjectPage {
  width: 100%;
  // height: 5000px;
  position: relative;
  background-image: url("~@/assets/img/activeTwelve/16.png");
  background-size: 100% 100%;
  overflow: hidden;

  .maintop_img {
    position: relative;
    width: 1497px;
    height: 918px;
    margin: 0 auto;
    z-index: 99;

    .left_grid {
      width: 493px;
      height: 372px;
      position: absolute;
      bottom: 119px;
      left: -215px;
    }

    .right_grid {
      width: 743px;
      height: 621px;
      position: absolute;
      bottom: -116px;
      right: -190px;
    }

    .active_time {
      width: 609px;
      height: 88px;
      position: absolute;
      bottom: 200px;
      left: 453px;
      z-index: 9;
    }

    .active_til {
      width: 1515px;
      height: 901px;
      position: absolute;
      top: 497px;
      left: -24px;
    }
  }

  .coupon {
    position: relative;
    width: 1256px;
    height: 972px;
    background-image: url("~@/assets/img/activeTwelve/15.png");
    background-size: 100% 100%;
    margin: 0 auto;
    top: 300px;

    .lantern_left {
      position: absolute;
      width: 472px;
      height: 853px;
      left: -362px;
    }

    .lantern_right {
      position: absolute;
      width: 472px;
      height: 853px;
      right: -391px;
    }

    .coupon_list {
      width: 1050px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 130px;
      margin-left: 130px;

      >div {
        width: 351px;
        height: 168px;
        border: 3px solid #f3d9a5;
        border-radius: 8px;
        background: linear-gradient(60deg,
            rgba(232, 25, 20, 1),
            rgba(255, 255, 255, 0.66),
            rgba(232, 25, 20, 1));
        cursor: pointer;

        .coupon_list_item {
          display: flex;

          >div:first-child {
            width: 110px;
            height: 82px;
            font-size: 99px;
            font-family: SourceHanSansSC;
            font-weight: bold;
            color: #800009;
            line-height: 149px;
            text-shadow: 0px 5px 3px rgba(154, 0, 5, 0.72);

            background: linear-gradient(0deg,
                #f9d3b9 0%,
                #fffcf4 51.025390625%,
                #f9d7be 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .coupon_mid {
      width: 788px;
      height: 256px;
      margin: 0 auto;
      margin-top: 50px;
      margin-left: 260px;
    }

    .coupon_bot {
      width: 1239px;
      height: 417px;
      margin: 0 auto;
      margin-top: -80px;
      margin-left: 58px;
      cursor: pointer;
    }
  }

  .reputation {
    width: 1355px;
    height: 1151px;
    background-image: url("~@/assets/img/activeTwelve/18.png");
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 400px;

    .reputation_til {
      position: relative;
      width: 902px;
      // margin: 0 auto;
      top: -120px;
      left: 246px;
    }

    .reputation_til_item {
      position: relative;
      top: -254px;
      left: 175px;

      .reputation_item {
        width: 1054px;
        height: 620px;
        position: relative;

        >div:nth-child(1) {
          position: absolute;
          font-size: 40px;
          font-family: SourceHanSansSC;
          font-weight: 500;
          color: #000000;
          text-align: center;
          width: 100%;
          top: 30px;
        }
      }

      .reputation_get {
        position: relative;
        width: 709px;
        height: 288px;
        left: 170px;
        top: -20px;
        cursor: pointer;
      }
    }
  }

  .twelve {
    position: relative;

    width: 1318px;
    // height: 1381px;
    background-image: url("~@/assets/img/activeTwelve/32.png");
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 50px;
    padding: 40px 0;

    .twelve_til {
      width: 804px;
      height: 347px;
      margin: 0 auto;
      position: absolute;
      left: 265px;
      top: -120px;

    }

    .twelve_list {
      width: 1100px;
      height: 270px;
      background-image: url("~@/assets/img/activeTwelve/41.png");
      background-size: 100% 100%;
      margin: 0 auto;
      margin-top: 50px;
      margin-left: 135px;
      position: relative;

      .twelve_lists {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 194px;

        .twelve_list_item {
          width: 177px;
          height: 69px;
          background-image: url("~@/assets/img/activeTwelve/44.png");
          background-size: 100% 100%;
          border-radius: 15px 15px 0px 0px;
          font-size: 25px;
          font-family: SourceHanSansSC;
          font-weight: bold;
          color: #000000;
          line-height: 69px;
          text-align: center;
          cursor: pointer;
        }

        .search1 {
          background-image: url("~@/assets/img/activeTwelve/45.png");
          background-size: 100% 100%;
          color: #FFF9DB;
        }
      }


    }

    .item_list {
      display: flex;
      justify-content: space-between;
      width: 1100px;
      margin-left: 135px;
      position: relative;
      flex-wrap: wrap;
      margin-bottom: 80px;
      margin-top: 30px;

      .list_item {
        width: 357px;
        height: 401px;
        background-image: url("~@/assets/img/activeTwelve/43.png");
        background-size: 100% 100%;
        margin-bottom: 23px;
        cursor: pointer;

        .item_til {
          width: 100%;
          height: 26px;
          font-size: 27px;
          font-family: SourceHanSansSC;
          font-weight: 400;
          color: #FEE5B0;
          line-height: 26px;
          text-align: center;
          margin-top: 30px;

        }
      }
    }
  }

  .twelves {
    width: 1318px;
    height: 2242px;
    background-image: url("~@/assets/img/activeTwelve/40.png");
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: -20px;
  }

  .fot {
    width: 1318px;
    height: 566px;
    background-image: url("~@/assets/img/activeTwelve/42.png");
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: -30px;
    position: relative;

    .fot_connect {
      width: 1120px;
      font-size: 32px;
      font-family: SourceHanSansSC;
      font-weight: 400;
      color: #FFEFC7;
      margin-left: 135px;
      line-height: 56px;
      position: relative;
      top: 200px;
    }

  }
}

img {
  width: 100%;
  height: 100%;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg) translateZ(-100px);
  }

  100% {
    transform: rotateY(360deg) translateZ(0px);
  }
}

.pageAll {
  width: 100%;
  position: fixed;
  top: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  // opacity: 0.5;
}

.upDio {
  position: fixed;
  width: 800px;
  // height: 580rpx;
  // background: #ffffff;
  box-shadow: 0rpx 20px 40px 0px rgba(28, 23, 47, 0.2);
  border-radius: 28px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
